<template>
  <div>
    <el-form label-position="top" v-if="orderData">
      <el-row>
        <el-col :span="20" :offset="2">
          <!-- Бүх хуудсанд ашиглагдах header section -->
          <custom-header title="Захиалгийн мэдээлэл"/>
           <el-steps :space="200" :active="activeStep" finish-status="success" align-center  style="justify-content: center" class="mb20">
            <el-step title="Жолоочид хуваарилах"></el-step>
            <el-step title="Жолоочид хуваарилсан"></el-step>
            <el-step title="Хүргэлтэнд гарсан"></el-step>
            <el-step title="Очсон"></el-step>
            <el-step title="Амжилттай"></el-step>
          </el-steps>
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <header>Сагс</header>
                  <ul class="transfer-product-list">
                    <div>
                            <strong>Захиалгын дугаар:{{ orderData.order_number}}</strong>
                      </div>
                    <div v-for="(product) in orderData.OrderItems" :key="product.product_id">
                      <el-row :gutter="10" class="mb20" style="background-color: orange; padding: 10px; border-radius: 6px" type="flex" align="middle" v-if="product.is_discount" >
                        <el-col :span="8">
                          <div>
                            {{ product.product_name + " - " + product.variant_name}}
                          </div>
                          <div class="text-color-secondary">
                            {{product.sku}}
                          </div>
                        </el-col>
                        <el-col :span="11">
                          <el-row :gutter="5" type="flex" align="middle">
                            <el-col :span="8" class="text-left">{{product.supplier_name}}</el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="12">
                          <el-row :gutter="5" type="flex" align="middle">
                            <el-col :span="8" class="text-left"> = {{generateCurrency(product.menu_price * product.item_count)}}</el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb20" style="padding: 10px" type="flex" align="middle" v-else >
                        <el-col :span="8">
                          <div>
                            {{ product.variant_name === 'default' ? product.product_name : product.product_name + " - " + product.variant_name}}
                          </div>
                          <div class="text-color-secondary">
                            {{product.sku}}
                          </div>
                        </el-col>
                        <el-col :span="11">
                          <el-row :gutter="5" type="flex" align="middle">
                            <el-col :span="8" class="text-left">{{product.supplier_name}}</el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="12">
                          <el-row :gutter="5" type="flex" align="middle">
                            <el-col :span="8" class="text-right">{{generateCurrency(product.menu_price)}} x  {{parseInt(product.item_count)}}</el-col>
                            <el-col :span="8" class="text-left"> = {{generateCurrency(product.menu_price * product.item_count)}}</el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </div>
                  </ul>
                  <header v-if="orderData.OrderDiscounts && orderData.OrderDiscounts.length !== 0">Хөнгөлөлт</header>
                  <ul class="transfer-product-list" v-if="orderData.OrderDiscounts && orderData.OrderDiscounts.length !== 0">
                    <div v-for="(discount) in orderData.OrderDiscounts" :key="discount.discount_id">
                      <el-row :gutter="10" class="mb20" style="padding: 10px" type="flex" align="middle">
                        <el-col :span="8">
                          <div>{{discount.discount_title_mon}}</div>
                          <div class="text-color-secondary" style="margin-top:5px; margin-bottom:5px">{{generateCouponType(discount.discount_type)}}</div>
                          <b v-if="discount.discount_type === 'PERCENTAGE'">Хөнгөлөлт - {{ discount.discount.saled_percentage }} %</b>
                          <b v-else-if="discount.discount_type === 'FIXED_AMOUNT'">Хөнгөлөлт - {{ generateCurrency(discount.discount.saled_price) }}</b>
                        </el-col>
                        <el-col :span="11">
                          <div>{{ discount.discount_code }}</div>
                        </el-col>
                      </el-row>
                    </div>
                  </ul>
                </div>
               <div class="panel-item">
                  <header>Захиалгын дэлгэрэнгүй</header>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Захиалгын тэмдэглэл" class="mb20">
                        <el-input v-model="orderData.orderNote" type="textarea" rows="3" :disabled="true"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-row :gutter="10" class="mb20">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Барааны нийт үнэ
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{orderData.menu_total}}</div>
                        </el-col>
                      </el-row>
                      <!-- <el-row :gutter="10" class="mb20">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Хүргэлтийн төлбөр
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderData.delivery_charge)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb20" v-if="orderData.delivery_fee && parseInt(orderData.delivery_fee) !== 0">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Бага дүнтэй захиалгын хураамж
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderData.delivery_fee)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb20" v-if="orderData.discount_total && parseInt(orderData.discount_total) !== 0 && (orderData.pay_total_no_discount - orderData.pay_total ) !== 0">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Хөнгөлөлтгүй дүн
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderData.pay_total_no_discount)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb20" v-if="orderData.discount_total && parseInt(orderData.discount_total) !== 0">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Хөнгөлөлтийн дүн
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderData.discount_total)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            <strong> Төлөх дүн </strong>
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>
                            <strong>{{generateCurrency(orderData.pay_total)}}</strong>
                          </div>
                        </el-col>
                      </el-row> -->
                      <el-row :gutter="10">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            <strong> Төлбөрийн статус </strong>
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div style="color: red;" v-if="orderData.pay_status === 'Төлөөгүй'">
                            <strong>{{orderData.pay_status}}</strong>
                          </div>
                          <div style="color: green;" v-else>
                            <strong>{{orderData.pay_status}}</strong>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <header>Жолоочийн нэр</header>
                  <el-form-item>
                    <el-input v-model="orderData.driver_name" type="type" :disabled="true"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="panel">
                <div class="panel-item">
                  <header>Хүргэлтийн хаяг</header>
                  <el-form-item label="Хаяг">
                    <el-input v-model="orderData.full_address" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="Орц">
                    <el-input v-model="orderData.addr_orts" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="Хаалга">
                    <el-input v-model="orderData.addr_haalga" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="Орцны код">
                    <el-input v-model="orderData.addr_orts_code" :disabled="true" ></el-input>
                  </el-form-item>
                  <el-form-item label="Нэмэлт">
                    <el-input v-model="orderData.desc" type="textarea" :rows="3" :disabled="true"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="text-right border-top pt20">
            <el-button type="default" @click="closeOrderdetail">Буцах</el-button>
            <el-button v-if="orderData.pay_status !== 'Төлөөгүй'" type="success" @click="completeOrder">Дуусгах</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <loader :active="isLoading" is-full-screen />
  </div>
</template>

<script>
import services from '../../../helpers/services'
import helper from '../../../helpers/helper'
import customHeader from '../../../components/common/customHeader'
import loader from '../../../components/common/loader'
import { getSupplierId } from '../../../utils/auth'
export default {
  name: 'OrderDetail',
  components: {
    customHeader: customHeader,
    loader: loader
  },

  async created () {
    if (this.$route.params.id) {
      const supplierId = await getSupplierId()
      this.getOrderItemsByOrderId(supplierId, this.$route.params.id)
    }
  },

  data () {
    return {
      activeStep: 2,
      orderData: null,
      isLoading: false,
      isLoadingButton: false,
      driverList: [],
      selectedDriver: null,
      userName: ''
    }
  },

  methods: {
    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },
    completeOrder () {
      this.$router.go(-1)
    },
    closeOrderdetail () {
      this.$router.go(-1)
    },
    getOrderItemsByOrderId (supplierIds, id) {
      this.isLoading = true
      const body = {
        supplier_id: supplierIds,
        order_id: id
      }
      services.getOrderItemsByOrderId(body).then(response => {
        this.isLoading = false
        if (response.status === 'success') {
          this.orderData = response.data
        } else {
          this.closeOrderdetail()
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },
    getDrivers () {
      services.getDrivers('active').then(response => {
        if (response.status === 'success') {
          this.driverList = response.data
        } else {
          this.closeOrderdetail()
        }
      })
    },
    driverOutByDriver () {
      this.isLoadingButton = true
      const body = {
        order_id: this.orderData.id,
        user_name: this.userName,
        driver_name: this.orderData.driver_name
      }
      services.driverOutByDriver(body).then(response => {
        this.isLoadingButton = false
        if (response.status === 'success') {
          this.orderData = response.data
          this.calculateOrderStatus(response.data.order_status && response.data.order_status !== '' ? response.data.order_status : 'Хүргэлтэнд гарсан')
          this.alertReporter('Амжилттай', response.message, 'success')
        } else {
          this.alertReporter('Алдаа гарлаа', response.message, 'unsuccess')
        }
      })
    },
    driverInByDriver () {
      this.isLoadingButton = true
      const body = {
        order_id: this.orderData.id,
        user_name: this.userName,
        driver_name: this.orderData.driver_name
      }
      services.driverInByDriver(body).then(response => {
        this.isLoadingButton = false
        if (response.status === 'success') {
          this.orderData = response.data
          this.calculateOrderStatus(response.data.order_status && response.data.order_status !== '' ? response.data.order_status : 'Очсон')
          this.alertReporter('Амжилттай', response.message, 'success')
        } else {
          this.alertReporter('Алдаа гарлаа', response.message, 'unsuccess')
        }
      })
    },
    orderCompleteByDriver () {
      this.isLoadingButton = true
      const body = {
        order_id: this.orderData.id,
        user_name: this.userName
      }
      services.orderCompleteByDriver(body).then(response => {
        this.isLoadingButton = false
        if (response.status === 'success') {
          this.orderData = response.data
          this.calculateOrderStatus(response.data.order_status && response.data.order_status !== '' ? response.data.order_status : 'Амжилттай')
          this.alertReporter('Амжилттай', response.message, 'success')
        } else {
          this.alertReporter('Алдаа гарлаа', response.message, 'unsuccess')
        }
      })
    }
  }
}
</script>

<style>
  /* .color: {
    background-color: green;
  } */

/* .el-input__inner {
  background-color: rgb(40,52,67)!important;
  border: solid rgb(40,52,67) 1px;
  color: white;
} */

</style>
